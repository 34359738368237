<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">회원명/번호/법인명</div>
            <ul class="content">
              <li class="item">
                <input-text
                  v-model="searchOptions.memberName"
                  @change="onMemberNameAndCprNameChanged"
                />
              </li>
              <li class="item">
                <input-text
                  v-model="searchOptions.memberNo"
                  @change="onMemberNameAndCprNameChanged"
                />
              </li>
              <li class="item">
                <input-text
                  v-model="searchOptions.cprName"
                  @change="onMemberNameAndCprNameChanged"
                />
              </li>
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                      button-div="GET"
                      @click.native="onMemberSearchButtonClicked"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field">
            <ul class="content">
              <li class="item">
                <ul class="check">
                  <li>
                    <label>
                      <input
                        type="checkbox"
                        v-model="searchOptions.isLinkMember"
                      />
                      <i></i>
                      <div class="label">연계회원</div>
                    </label>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="field" v-if="false">
            <div class="title">기준년도</div>
            <ul class="content">
              <li class="item year">
                <input-number
                  v-model="searchOptions.bsnYear"
                  :allowDot="false"
                  :allowMinus="false"
                  :displayComma="false"
                  :propMaxLength="4"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="searchOptions.bsnDate"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              @click.native="searchButtonClicked"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article
        :class="[
          'body-article',
          'article-01',
          { close: !isMemberInfomationVisible }
        ]"
      >
        <section
          :class="[
            'article-section',
            'section-01',
            'accordion',
            { close: !isMemberInfomationVisible }
          ]"
        >
          <div class="section-header">
            <div class="header-left">
              <div
                class="header-title"
                @click="isMemberInfomationVisible = !isMemberInfomationVisible"
              >
                정회원/가족회원 정보
              </div>
            </div>
          </div>
          <div class="section-body">
            <article class="body-article">
              <div class="article-left">
                <section class="article-section section-0101">
                  <div class="section-body">
                    <div class="body-card">
                      <ul class="box">
                        <li
                          class="card"
                          v-for="(memberInfo, idx) in memberVisitStatus.memberInfos"
                          :key="memberInfo.membershipId"
                          :class="{
                            'active': memberCardSelectedIdx === idx
                          }"
                          @click="memberInfoCardClicked($event, memberInfo, idx)"
                        >
                          <div class="photo">
                            <div
                              v-if="memberInfo['imagePath']"
                              class="item"
                              :style="{
                                backgroundImage: memberInfo.imagePath ? `url(${memberInfo['imagePath']})` : null,
                              }"
                            ></div>
                          </div>
                          <ul class="content">
                            <li class="item">
                              <strong>{{ memberInfo.memberName }}</strong>
                              <span v-if="memberInfo.memberNo">({{ gridUtilGetMemberNoColumnAccess("memberNo", memberInfo) }})</span>
                            </li>
                            <li class="item">
                              {{
                                commonCodesGetComName("MEMBER_DIV", memberInfo.memberDiv)
                              }}/{{
                                commonCodesGetComName("MEMBER_GRADE", memberInfo.memberGrade)
                              }}
                            </li>
                            <li class="item" v-if="memberInfo.cprName">
                              {{ memberInfo.cprName }}
                            </li>
                            <li class="item">
                              {{
                                getFormattedPhoneNumber(memberInfo.hpNo)
                              }}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </div>
              <div class="article-right">
                <section class="article-section section-0102">
                  <div class="section-body">{{ benefitDescription }}</div>
                </section>
              </div>
            </article>
          </div>
        </section>
      </article>
      <article
        :class="[
          'body-article',
          'article-02',
          { open: !isMemberInfomationVisible }
        ]"
      >
        <section class="article-section section-02">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">
                회원내장현황
              </div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-card">
              <ul class="box">
                <li
                  class="card"
                  v-for="(month, idx) in memberVisitStatus.monthlyStatus"
                  :key="month.monthDiv"
                  :class="{
                    'monthly-status-tab-select': tabSelectedIdx === idx,
                    'no-data': !((month.weekdayCount ? month.weekdayCount : 0) > 0) && !((month.weekendCount ? month.weekendCount : 0) > 0)
                  }"
                  @click="monthlyStatusTabSelected($event, month, idx)"
                >
                  <div class="card-left">
                    <ul class="content">
                      <li class="item">
                        {{ month.header.text }}
                      </li>
                    </ul>
                  </div>
                  <div class="card-right">
                    <ul class="content">
                      <li class="item">
                        <div class="sub-item">
                          주중 {{month.weekdayCount}}회
                        </div>
                        <div class="sub-item weekend">
                          <span
                            :style="{
                              color: !((month.weekdayCount ? month.weekdayCount : 0) > 0) && !((month.weekendCount ? month.weekendCount : 0) > 0) ? null : commonCodesGetColorValue('BSN_CODE', 'WEEKEND')
                            }"
                          >주말</span> {{month.weekendCount}}회
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div class="body-grid">
              <ejs-grid-wrapper
                ref="monthlyStatusGrid"
                v-bind="monthlyStatusGridOptions"
                :dataSource="memberVisitStatus.monthlyStatusList"
                @queryCellInfo="monthlyStatusGridQueryCellInfo"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
    <member-select-popup
      v-if="isMemberSelectPopupOpen"
      ref="memberSelectPopup"
      :position="memberPopupPosition"
      :isModal="true"
      @popupClosed="memberSelectPopupClosed"
      @popupConfirmed="memberSelectPopupConfirmed"
    />
  </div>
</template>

<style scoped>
body .appContent .content-lookup .lookup-left .lookup-condition .field .item.year {width: 90px;}

body .appContent .body-article.article-01.close {height: 64px;}
body .appContent .body-article.article-01:not(.close) {height: 226px;}
body .appContent .body-article.article-02.open {height: calc(100% - 64px)}
body .appContent .body-article.article-02:not(.open) {height: calc(100% - 226px)}

body .appContent .body-article.article-01 .article-section.section-01 .section-body .body-article,
body .appContent .body-article.article-01 .article-section.section-01 .section-body .body-article .article-left .article-section.section-0101 {padding: 0;}
body .appContent .article-section.section-01 .section-body .body-article .article-left {width: calc(((100% / 3) * 2) - 1px); border-right: 1px solid #ccc;}
body .appContent .article-section.section-0101 .section-body {overflow: visible; border: none;}
body .appContent .article-section.section-0101 .body-card {overflow-x: scroll; overflow-y: hidden; height: 100%;}
body .appContent .article-section.section-0101 .body-card .box {display: block; list-style: none; padding: 0px; margin: 0;}
@supports (display: flow-root) {
  body .appContent .article-section.section-0101 .body-card .box {display: flow-root;}
}
body .appContent .article-section.section-0101 .body-card .box:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-0101 .body-card .card {display: block; box-sizing: border-box; float: left; width: 240px; height: 131px; padding: 9px; margin: 6px 0 6px 6px; border: 1px solid #ccc; border-radius: 3px; background-color: #f9f9f9; cursor: pointer;}
@supports (display: flow-root) {
  body .appContent .article-section.section-0101 .body-card .card {display: flow-root;}
}
body .appContent .article-section.section-0101 .body-card .card.active {background-color: #ecfaf0;}
body .appContent .article-section.section-0101 .body-card .card:hover {background-color: #f0f0f0;}
body .appContent .article-section.section-0101 .body-card .card:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-0101 .body-card .photo {box-sizing: border-box; float: left; width: 82px; height: 100%; border: 1px solid #e0e0e0; background: #fff url('../../assets/images/common/photo-default.png') no-repeat center center; background-size: 40%;}
body .appContent .article-section.section-0101 .body-card .photo .item {width: 100%; height: 100%; background-color: #fff; background-repeat: no-repeat; background-position: center center; background-size: contain;}
body .appContent .article-section.section-0101 .body-card .content {box-sizing: border-box; list-style: none; float: left; width: calc(100% - 82px); padding: 5px 0 5px 12px; margin: 0;}
body .appContent .article-section.section-0101 .body-card .content .item {overflow: hidden; width: 100%; min-height: 18px; text-overflow: ellipsis; white-space: nowrap;}

body .appContent .article-section.section-01 .section-body .body-article .article-right {width: calc((100% / 3) - 1px);}

body .appContent .article-section.section-02 .section-body {overflow: visible; border: none;}
body .appContent .article-section.section-02 .body-card {overflow-x: hidden; overflow-y: hidden; height: 72px;}
body .appContent .article-section.section-02 .body-card .box {display: block; list-style: none; padding: 0px; margin: 0;}
@supports (display: flow-root) {
  body .appContent .article-section.section-02 .body-card .box {display: flow-root;}
}
body .appContent .article-section.section-02 .body-card .box:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-02 .body-card .card {display: block; box-sizing: border-box; float: left; width: 118px; height: 60px; padding: 6px; margin: 6px 0 6px 6px; border: 1px solid #ccc; border-radius: 3px; background-color: #fff; cursor: pointer;}
body .appContent .article-section.section-02 .body-card .card.monthly-status-tab-select {color: #fff; background-color: #2e7458; border: none;}
body .appContent .article-section.section-02 .body-card .card.no-data {color: #ccc; background-color: #fff; border: 1px solid #ccc; cursor: auto;}
body .wrapper.dev-layout-left-no-right-tab-yes-right-content-no .appContent .article-section.section-02 .body-card .card {width: 134px;}
@supports (display: flow-root) {
  body .appContent .article-section.section-02 .body-card .card {display: flow-root;}
}
body .appContent .article-section.section-02 .body-card .card:after {display: block; clear: both; height: 0; font-size: 0; line-height: 0; content: '';}
body .appContent .article-section.section-02 .body-card .photo {box-sizing: border-box; float: left; width: 82px; height: 100%; border: 1px solid #e0e0e0; background: #fff url('../../assets/images/common/photo-default.png') no-repeat center center; background-size: 40%;}
body .appContent .article-section.section-02 .body-card .photo .item {width: 100%; height: 100%; background-color: #fff; background-repeat: no-repeat; background-position: center center; background-size: contain;}
body .appContent .article-section.section-02 .body-card .card-left  {width: 40%; height: 100%; float: left;}
body .appContent .article-section.section-02 .body-card .card-right {width: 60%; height: 100%; float: left;}
body .appContent .article-section.section-02 .body-card .content {box-sizing: border-box; list-style: none; float: left; width: 100%; height: 100%; padding: 0px 0 0px 6px; margin: 0;}
body .appContent .article-section.section-02 .body-card .content .item {overflow: hidden; width: 100%; height: 100%; min-height: 18px; text-overflow: ellipsis; white-space: nowrap; line-height: 46px;}
body .appContent .article-section.section-02 .body-card .content .item .sub-item {line-height: 23px;}

body .appContent .article-section.section-02 .section-body {height: calc(100% - 46px);}
body .appContent .article-section.section-02 .body-grid {height: calc(100% - 72px);}
body .appContent .article-section.section-02 .body-grid .body-grid {height: 100%;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import InputText from "@/components/common/text/InputText";
import InputNumber from "@/components/common/InputNumber";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import {Aggregate, ExcelExport, Filter, ForeignKey, Page, Group, Resize} from '@syncfusion/ej2-vue-grids';
import memberSelectPopup from "@/views/common/MemberSelectPopup";
import ErpButton from "@/components/button/ErpButton.vue";

import {
  commonCodesGetComName,
  commonCodesGetColorValue, commonCodesGetSortNo,
} from "@/utils/commonCodes";
import {
  getFormattedPhoneNumber
} from "@/utils/string";
import {
  gridUtilGetMemberNoColumnAccess
} from "@/utils/gridUtil";
import {
  getTodayNavigationDate,
  getFormattedDate,
} from "@/utils/date";
import { orderBy as _orderBy } from "lodash";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import InputDateRange from "@/components/common/datetime/InputDateRange.vue";

export default {
  name: "MemberVisitStatus",
  components: {
    InputDateRange,
    EjsGridWrapper,
    ErpButton,
    InputText,
    InputNumber,
    memberSelectPopup,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  watch: {},
  data() {
    return {
      isMemberInfomationVisible: true,
      isMemberSelectPopupOpen: false, // 유저 선택 팝업 Open 여부
      memberPopupPosition: { x: "center", y: "center" },
      monthlyStatusGridOptions: {
        provides: [
          Aggregate,
          Filter,
          Resize,
          Page,
          Group,
          ExcelExport,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Both",
          enableToggle: false,
          persistSelection: false,
        },
        groupSettings: {
          columns: ["resveDateByYYYYMM"],
          showDropArea: false,
        },
        aggregates: [
          {
            columns: [
              {
                field: "resveDateByMMDD",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  return data.key.substring(4) + "월 (" + (this.memberVisitStatus.monthlyStatusList.filter(item => item.resveDateByYYYYMM === data.key).length) + "회)";
                },
              },
              {
                field: "bsnCode",
                aggregationType: "GroupCaption",
                customAggregate: "주중",
              },
              {
                field: "resveKind",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  return (this.memberVisitStatus.monthlyStatusList.filter(item => item.resveDateByYYYYMM === data.key && item.bsnCode === "WEEKDAY").length) + "회";
                },
              },
              {
                field: "resveTime",
                aggregationType: "GroupCaption",
                customAggregate: "주말",
              },
              {
                field: "resveCourse",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  return (this.memberVisitStatus.monthlyStatusList.filter(item => item.resveDateByYYYYMM === data.key && item.bsnCode === "WEEKEND").length) + "회";
                },
              },
              {
                field: "resveName",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  return "일반 " + (this.memberVisitStatus.monthlyStatusList.filter(item => item.resveDateByYYYYMM === data.key && item.resveKind === "NOR").length) + "회";
                },
              },
              {
                field: "memberNo",
                aggregationType: "GroupCaption",
                customAggregate: (data) => {
                  return "위임 " + (this.memberVisitStatus.monthlyStatusList.filter(item => item.resveDateByYYYYMM === data.key && item.resveKind === "TRANS").length) + "회";
                },
              },
              {
                field: "feeSalesTotAmt",
                aggregationType: "GroupSum"
              },
              {
                field: "rentSalesTotAmt",
                aggregationType: "GroupSum"
              },
              {
                field: "foodSalesTotAmt",
                aggregationType: "GroupSum"
              },
              {
                field: "shopSalesTotAmt",
                aggregationType: "GroupSum"
              },
              {
                field: "etcSalesTotAmt",
                aggregationType: "GroupSum"
              },
              {
                field: "salesTotAmt",
                aggregationType: "GroupSum"
              },
            ]
          }
        ],
        columns: [
          {
            field: "timeId",
            visible: false,
            isPrimaryKey: true,
          },
          {
            headerText: "예약정보",
            columns: [
              {
                field: "resveDateByMMDD",
                headerText: "일자",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
              },
              {
                field: "dwCode",
                headerText: "요일",
                type: "string",
                minWidth: 16,
                width: 70,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "DW_CODE",
              },
              {
                field: "bsnCode",
                headerText: "영업",
                type: "string",
                minWidth: 16,
                width: 70,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "BSN_CODE",
              },
              {
                field: "resveKind",
                headerText: "예약종류",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "RESVE_KIND",
              },
              {
                field: "resveTime",
                headerText: "시간",
                type: "string",
                minWidth: 16,
                width: 70,
                textAlign: "center",
              },
              {
                field: "resveCourse",
                headerText: "코스",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "COURSE_CODE",
              },
              {
                field: "resveChannel",
                headerText: "예약채널",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "RESVE_CHANNEL",
              },
              {
                field: "grpName",
                headerText: "단체명",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "resveName",
                headerText: "예약자명",
                type: "string",
                minWidth: 16,
                width: 110,
                textAlign: "center",
              },
              {
                field: "memberNo",
                headerText: "회원번호",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                valueAccessor: (field, data) =>
                  gridUtilGetMemberNoColumnAccess(field, data),
              },
              {
                field: "memberDiv",
                headerText: "회원구분",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "MEMBER_DIV",
              },
              {
                field: "memberGrade",
                headerText: "회원등급",
                type: "string",
                minWidth: 16,
                width: 90,
                textAlign: "center",
                isCommonCodeField: true,
                groupCode: "MEMBER_GRADE",
              },
            ]
          },
          {
            headerText: "내장객",
            columns: [
              {
                field: "visitName1",
                headerText: "1",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName2",
                headerText: "2",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName3",
                headerText: "3",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName4",
                headerText: "4",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
              {
                field: "visitName5",
                headerText: "5",
                type: "string",
                minWidth: 16,
                width: 80,
                textAlign: "center",
              },
            ]
          },
          {
            headerText: "매출정보",
            columns: [
              {
                field: "feeSalesTotAmt",
                headerText: "입장료",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 110,
                textAlign: "right",
              },
              {
                field: "rentSalesTotAmt",
                headerText: "대여료",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 110,
                textAlign: "right",
              },
              {
                field: "foodSalesTotAmt",
                headerText: "식음료",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 110,
                textAlign: "right",
              },
              {
                field: "shopSalesTotAmt",
                headerText: "프로샵",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 110,
                textAlign: "right",
              },
              {
                field: "etcSalesTotAmt",
                headerText: "기타",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 110,
                textAlign: "right",
              },
              {
                field: "salesTotAmt",
                headerText: "합계",
                type: "number",
                format: "N",
                minWidth: 16,
                width: 110,
                textAlign: "right",
              },
            ]
          },
          {
            field: "resveDateByYYYYMM",
            type: "string",
          }
        ],
        allowGrouping: true,
        allowExcelExport: true
      },
      tabSelectedIdx: 0,
      searchOptions: {
        bsnYear: null,
        bsnDate: { from: null, to: null },
        membershipId: null,
        memberName: null,
        memberNo: null,
        cprName: null,
        isLinkMember: true,
      },
      memberVisitStatus: {
        memberInfos: [],
        monthlyStatus: [
          {
            header: { text: "ALL" },
            monthDiv: "ALL",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "1월" },
            monthDiv: "01",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "2월" },
            monthDiv: "02",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "3월" },
            monthDiv: "03",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "4월" },
            monthDiv: "04",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "5월" },
            monthDiv: "05",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "6월" },
            monthDiv: "06",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "7월" },
            monthDiv: "07",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "8월" },
            monthDiv: "08",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "9월" },
            monthDiv: "09",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "10월" },
            monthDiv: "10",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "11월" },
            monthDiv: "11",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "12월" },
            monthDiv: "12",
            weekdayCount: 0,
            weekendCount: 0,
          },
        ],
        monthlyStatusList: [],
      },
      benefitDescription: null,
      memberCardSelectedIdx: null,
    };
  },
  computed: {},
  async created() {
    const nowYear = await getTodayNavigationDate("YYYY");

    this.searchOptions.bsnYear = nowYear;
    this.searchOptions.bsnDate.from = nowYear+"-01-01";
    this.searchOptions.bsnDate.to = nowYear+"-12-31";
  },
  mounted() {},
  methods: {
    commonCodesGetComName,
    commonCodesGetColorValue,
    getFormattedPhoneNumber,
    gridUtilGetMemberNoColumnAccess,
    async searchButtonClicked() {
      if (!this.searchOptions.membershipId) {
        this.infoToast("조회하려는 회원을 선택하여 주시기 바랍니다");
        return;
      }

      const data = await GolfErpAPI.fetchMemberVisitStatus(this.searchOptions);

      this.memberVisitStatus.memberInfos = data.memberInfos.map(member => {
        return {
          membershipId: member.id,
          imagePath: member.member?.imageFile?.path,
          memberName: member.member?.name,
          memberNo: member.memberNumber,
          memberDiv: member.memberDivision,
          memberGrade: member.memberGrade,
          cprName: member.corporationName,
          hpNo: member.member?.phoneNumber,
          benefitDescription: member.benefitDescription,
        };
      });

      this.memberVisitStatus.monthlyStatus.map(month => {
        if (month.monthDiv === "ALL") {
          month.weekdayCount = data.monthlyStatusList.filter(item => item.bsnCode === "WEEKDAY").length;
          month.weekendCount = data.monthlyStatusList.filter(item => item.bsnCode === "WEEKEND").length;
        } else {
          month.weekdayCount = data.monthlyStatusList.filter(item => getFormattedDate(item.resveDate, "MM") === month.monthDiv && item.bsnCode === "WEEKDAY").length;
          month.weekendCount = data.monthlyStatusList.filter(item => getFormattedDate(item.resveDate, "MM") === month.monthDiv && item.bsnCode === "WEEKEND").length;
        }
      });

      if (this.memberVisitStatus.memberInfos.length > 0) {
        this.memberInfoCardClicked(null, this.memberVisitStatus.memberInfos[0], 0);
      }

      if (this.tabSelectedIdx !== null) {
        const monthDiv = this.memberVisitStatus.monthlyStatus[this.tabSelectedIdx].monthDiv;

        this.memberVisitStatus.monthlyStatusList =
          _orderBy(
            (monthDiv === "ALL" ? data.monthlyStatusList : data.monthlyStatusList.filter(item => getFormattedDate(item.resveDate, "MM") === monthDiv)).map(item => ({
              ...item,
              resveDateByMMDD: getFormattedDate(item.resveDate, "MM-DD"),
              resveDateByYYYYMM: getFormattedDate(item.resveDate, "YYYYMM"),
              resveKind: item.reservationConfirm?.reservationMember?.resveKind,
              resveChannel: item.reservationConfirm?.reservationMember?.resveChannel,
              grpName: item.reservationConfirm?.reservationMember?.grpName,
              // resveName: item.reservationConfirm?.reservationMember?.resveName,
              //transName 있으면 무조건 보이는 건지? 종류가 위임경우만 보이건지 확인 필요?
              resveName: item.transName ? item.reservationConfirm?.reservationMember?.resveName + " (" + item.transName + ")" : item.reservationConfirm?.reservationMember?.resveName,
              memberNo: item.reservationConfirm?.reservationMember?.memberNo,
              memberDiv: item.reservationConfirm?.reservationMember?.memberDiv,
              memberGrade: item.reservationConfirm?.reservationMember?.memberGrade,
              visitName1: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 1)[0]?.name,
              visitName2: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 2)[0]?.name,
              visitName3: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 3)[0]?.name,
              visitName4: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 4)[0]?.name,
              visitName5: item.reservationConfirm?.team?.visitors?.filter(visitor => visitor.order === 5)[0]?.name,
              feeSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.feeSalesTotAmt,
              rentSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.rentSalesTotAmt,
              foodSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.foodSalesTotAmt,
              shopSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.shopSalesTotAmt,
              etcSalesTotAmt: item.reservationConfirm?.salesAmountByResveId?.etcSalesTotAmt,
              salesTotAmt: item.reservationConfirm?.salesAmountByResveId?.salesTotAmt,
              courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.resveCourse),

            })), ["resveDate", "resveTime", "courseCodeSortNo"], ["desc", "asc", "asc"])
        ;
      }
    },
    async monthlyStatusTabSelected(args, data, tabIndex) {
      if (!(data.weekdayCount > 0) && !(data.weekendCount > 0)) {
        return;
      }

      this.tabSelectedIdx = tabIndex;

      await this.searchButtonClicked();
    },
    noMemberInfoSearchOptionsInit() {
      this.searchOptions.membershipId = null;
      this.searchOptions.memberName = null;
      this.searchOptions.memberNo = null;
      this.searchOptions.cprName = null;

      this.memberVisitStatusDataInit();
    },
    onMemberNameAndCprNameChanged(args) {
      if (args.event && args.previousValue !== args.value) {
        this.$EventBus.$emit("loaderOn");
        try {
          if (args.value.trim() === "") {
            this.noMemberInfoSearchOptionsInit();
            return;
          }

          // 회원정보에 없는 비회원이라도 무조건 회원 검색 팝업 호출. 모든 화면 공통 조건.
          this.onMemberPopupOpen(
            false,
            null,
            args.value,
            null
          );
        } catch (error) {
          console.log("getMemberList.err.===>", error);
        } finally {
          this.$EventBus.$emit("loaderOff");
        }
      }
    },
    onMemberPopupOpen(
      isOpenedWithButton,
      popupType,
      memberNameNo,
      timeInfoIdx,
      selectedIdx,
      memberDivChanged = false,
      memberGradeChanged = false
    ) {
      this.isMemberSelectPopupOpen = true;

      let memberData = {};
      memberData.memberNameNo = memberNameNo;
      memberData.cprName = this.searchOptions.cprName;
      memberData.isOpenedWithButton = isOpenedWithButton;
      memberData.popupType = popupType;
      memberData.memberDivChanged = memberDivChanged;
      memberData.memberGradeChanged = memberGradeChanged;
      memberData.nomDataViewFlag = false;
      if (timeInfoIdx !== null && timeInfoIdx !== undefined) {
        memberData.timeInfoIdx = timeInfoIdx;
      }
      if (selectedIdx !== null && selectedIdx !== undefined) {
        memberData.selectedIdx = selectedIdx;
      }
      this.$nextTick(() => {
        this.$refs.memberSelectPopup.showPopup(memberData);
      });
    },
    memberSelectPopupClosed() {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      this.noMemberInfoSearchOptionsInit();
    },
    async memberSelectPopupConfirmed(data) {
      this.isMemberSelectPopupOpen = false; // 닫히면 DOM에서 없애버림. 새로 띄울 때 초기화시키기

      this.searchOptions.membershipId = data.selectedRowData.membershipId;

      if (data?.popupData?.isOpenedWithButton) {
        this.searchOptions.memberName = data.selectedRowData.memberName;
        this.searchOptions.memberNo = data.selectedRowData.memberNo;
        this.searchOptions.cprName = data.selectedRowData.cprName;
      }

      await this.searchButtonClicked();
    },
    memberVisitStatusDataInit() {
      this.memberVisitStatus = {
        memberInfos: [],
          monthlyStatus: [
          {
            header: { text: "ALL" },
            monthDiv: "ALL",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "1월" },
            monthDiv: "01",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "2월" },
            monthDiv: "02",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "3월" },
            monthDiv: "03",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "4월" },
            monthDiv: "04",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "5월" },
            monthDiv: "05",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "6월" },
            monthDiv: "06",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "7월" },
            monthDiv: "07",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "8월" },
            monthDiv: "08",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "9월" },
            monthDiv: "09",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "10월" },
            monthDiv: "10",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "11월" },
            monthDiv: "11",
            weekdayCount: 0,
            weekendCount: 0,
          },
          {
            header: { text: "12월" },
            monthDiv: "12",
            weekdayCount: 0,
            weekendCount: 0,
          },
        ],
          monthlyStatusList: [],
      };
      this.benefitDescription = null;
      this.memberCardSelectedIdx = null;
    },
    async onClickExcel() {
      if (this.memberVisitStatus.monthlyStatusList.length > 0) {
        this.$refs.monthlyStatusGrid.excelExport();
      }
    },
    memberInfoCardClicked(args, memberInfo, selectedIdx) {
      this.benefitDescription = memberInfo.benefitDescription;

      this.memberCardSelectedIdx = selectedIdx;
    },
    monthlyStatusGridQueryCellInfo(args) {
      const {
        column: {
          field
        },
        cell,
        data,
      } = args;

      if (field && field.endsWith("TotAmt")) {
        if (data[field] === 0) {
          cell.innerText = "-";
        }
      }

      if (field === "dwCode") {
        cell.style.color = commonCodesGetColorValue("DW_CODE", data.dwCode);
      } else if (field === "bsnCode") {
        cell.style.color = commonCodesGetColorValue("BSN_CODE", data.bsnCode);
      } else if (field === "resveKind") {
        cell.style.color = commonCodesGetColorValue("RESVE_KIND", data.resveKind);
      }
    },
    onMemberSearchButtonClicked() {
      this.onMemberPopupOpen(
        true,
        null,
        null,
        null
      );
    }
  },
};
</script>
